@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
body,
h1,
h2,
h3,
h4,
h5,
b,
li,
ul,
div,
span,
button,
p,
a {
    font-family: "Inter", sans-serif !important;
}
